// spacing
$spacer: 1rem;
$spacers: (
  // Normal Spacers
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  // Physician Portal Spacers
  8: $spacer * 0.5,
  12: $spacer * 0.75,
  16: $spacer,
  20: $spacer * 1.25,
  24: $spacer * 1.5,
  28: $spacer * 1.75,
  32: $spacer * 2,
  40: $spacer * 2.5,
  48: $spacer * 3,
  56: $spacer * 3.5,
  64: $spacer * 4,
  72: $spacer * 4.5,
  80: $spacer * 5,
  96: $spacer * 6
);
