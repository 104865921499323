// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff; // unchanged
$gray-100: #f2f2f2;
$gray-200: #e9ecef; // unchanged
$gray-300: #dee2e6; // unchanged
$gray-400: #ced4da; // unchanged
$gray-500: #b2b2b2;
$gray-600: #6c757d; // unchanged
$gray-700: #5c5c5c;
$gray-800: #343a40; // unchanged
$gray-900: #191919;
$gray-1000: #b2b2b2;
$black: #000;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
  '1000': $gray-1000,
);
// scss-docs-end gray-colors-map
// fusv-enable

// Invoy Colors
$i-blue: #2a3c56;
$i-green: #477e63;
$i-mint-green: #e4f5ec;
$i-denim-blue: #5c7fb3;
$i-oxford-blue: #e8f4fa;
$i-red: #eb5324;

$i-light-coral: #fae6d4;
$i-marigold: #fab52a;
$i-cream: #fff1d5;
$i-light-cream: #fffcf0;
$i-cream-light: #fcfaf2;
$i-beige: #dec4a6;
$i-turquoise: #79cdd1;

// scss-docs-start color-variables
$blue: $i-blue;
$indigo: $i-denim-blue;
$purple: #6f42c1; // unchanged
$pink: #d63384; // unchanged
$red: $i-red;
$orange: $i-marigold;
$yellow: $i-light-cream;
$green: $i-green;
$teal: #20c997; // unchanged
$cyan: $i-turquoise;
// scss-docs-end color-variables

// custom color map
$invoy-colors: (
  'i-blue': $i-blue,
  'i-green': $i-green,
  'i-mint-green': $i-mint-green,
  'i-denim-blue': $i-denim-blue,
  'i-oxford-blue': $i-oxford-blue,
  'i-red': $i-red,
  'i-light-coral': $i-light-coral,
  'i-marigold': $i-marigold,
  'i-cream': $i-cream,
  'i-light-cream': $i-light-cream,
  'i-cream-light': $i-cream-light,
  'i-beige': $i-beige,
  'i-turquoise': $i-turquoise,
  'i-white': $white,
  'i-grey-100': $gray-100,
  'i-grey-900': $gray-900,
);

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
  'i-light-cream': $i-light-cream,
  'i-cream-light': $i-cream-light,
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);
// scss-docs-end theme-colors-map

$theme-colors: map-merge($theme-colors, $invoy-colors);
